import { useQuery } from "@apollo/client";
import { Dialog } from "@material-ui/core";
import { parse } from "query-string";
import React, { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GET_REVIEW } from "./api";
import { Layout } from "./layout";
import { layoutStyles as useStyles } from "./layout.styles";

export const RestaurantReviewDialog: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const queryObj =
    location && location.search
      ? (parse(location.search) as { reviewId: string })
      : { reviewId: "0" };
  const { data, loading, error } = useQuery(GET_REVIEW, {
    variables: { input: queryObj.reviewId },
  });
  const obj = parse(location.search) as { reviewId?: string };
  const _removeQueryParam = () => {
    setOpen(false);
    history.replace({ search: "" });
  };

  if (!data || !data.getRestaurantReview || error) return null;

  return (
    <Dialog
      open={open}
      onClose={_removeQueryParam}
      classes={{ paperWidthSm: classes.paperWidthSm }}
    >
      <Layout
        review={data.getRestaurantReview}
        onClose={_removeQueryParam}
        url={`https://cookconnect.com${location.pathname}?reviewId=${obj.reviewId}`}
      />
    </Dialog>
  );
};
