import { useQuery } from "@apollo/client";
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Close, Share } from "@material-ui/icons";
import React, { useState } from "react";
import { ShareDialog } from "src/components";
import { sharedAPI } from "src/shared-graphql";
import { GET_DIALOG_RESTAURANT_NAME } from "src/shared-graphql/queries";
import { COLORS } from "src/styles";
import { RestaurantContainer } from "./restaurant-container";

const TransitionSlide = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RestaurantDialog = () => {
  const classes = useStyles();
  const { data } = useQuery(GET_DIALOG_RESTAURANT_NAME);

  const [shareDialog, setShareDialog] = useState(false);

  const restaurantName = data?.getDialogRestaurantName;

  const shareUrl = `https://www.cookconnect.com/${restaurantName
    ?.split(" ")
    .join("-")}`;

  return (
    <div>
      <Dialog
        fullScreen
        open={!!restaurantName}
        onClose={() => sharedAPI.setDialogRestaurantName("")}
        TransitionComponent={TransitionSlide}
      >
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              id="close-dialog-btn"
              edge="start"
              className={classes.closeBtn}
              onClick={() => sharedAPI.setDialogRestaurantName("")}
              aria-label="close"
            >
              <Close className={classes.closeIcon} />
            </IconButton>
            <Typography variant="h5" component="h5" className={classes.title}>
              {restaurantName}
            </Typography>
            <IconButton
              className={classes.shareBtn}
              onClick={() => {
                setShareDialog(true);
              }}
              aria-label="share"
            >
              <Share className={classes.shareIcon} />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}

        {restaurantName && (
          <DialogContent className={classes.dialogContent}>
            <RestaurantContainer restaurantName={restaurantName} />
          </DialogContent>
        )}
      </Dialog>

      <ShareDialog
        open={shareDialog}
        shareURL={shareUrl}
        onClose={() => setShareDialog(false)}
      />
    </div>
  );
};

const useStyles = makeStyles({
  closeBtn: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.23)",
    backgroundColor: COLORS.MEDIUM_GREEN,
    "&:hover": {
      backgroundColor: COLORS.LIGHT_GREEN,
    },
  },
  appBar: {
    backgroundColor: COLORS.WHITE,
  },
  closeIcon: {
    color: COLORS.WHITE,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  shareBtn: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.23)",
  },
  shareIcon: {
    color: COLORS.MEDIUM_GREEN,
  },
  title: {
    color: COLORS.BLACK,
    fontFamily: "CustomFourBold",
    textAlign: "center",
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  dialogContent: {
    padding: 0,
  },
  shareButtonsContainer: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
});
